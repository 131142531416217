import React from "react";
import KtlPic from "../../static/ktl.webp";
import gA from "../../ReactGA";

export default class Ktl extends React.Component {
  shotClicked() {
    gA("leftForKtlMtn");
  }

  render() {
    return (
      <div className="mt-3">
        <a
          href="https://photography.mister-life.com/albums/281437-dolly-sodds-wilderness-fall-2024"
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.shotClicked}
        >
          <img
            src="https://res.cloudinary.com/fleetnation/image/private/c_fit,w_1120/fl_no_overflow,g_south,l_text:style_gothic2:%C2%A9%20mister-life,o_20,y_10/fl_no_overflow,g_center,l_watermark4,o_25,y_50/v1720062494/z9dggi1zomk14108yolv.jpg"
            alt="Flower pics"
            style={{
              maxWidth: "100%",
              height: "380px",
            }}
          />
        </a>
      </div>
    );
  }
}
